<template>
  <div>
    <!-- 搜索区域 -->
    <div class="info_search">
      <a-row :gutter="24">
        <a-col :md="9" :sm="9">
          <j-date
              v-model="queryParam['startTime']"
              placeholder="请选择开始日期"
              :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
              date-format="YYYY-MM-DD HH:mm"
              style="min-width: 120px;width:calc(50% - 15px);"
          ></j-date>
          <span style="color:#BC0039" class="group-query-strig">&nbsp;~&nbsp;</span>
          <j-date
              v-model="queryParam['endTime']"
              :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
              date-format="YYYY-MM-DD HH:mm"
              placeholder="请选择结束日期"
              style="min-width: 120px;width:calc(50% - 15px);"
          ></j-date>
          <!-- <a-input
            class="search_input"
            v-model="queryParam.ydksrq"
            placeholder="预约时间"
          /> -->
        </a-col>
        <a-col :md="4" :sm="4">
          <a-input
            class="search_input"
            v-model="queryParam.sqr"
            placeholder="申请人"
          />
        </a-col>
        <a-col :md="4" :sm="4">
          <a-input
            class="search_input"
            v-model="queryParam.syr"
            placeholder="使用人"
          />
        </a-col>
        <a-col :md="4" :sm="4">
          <a-input
            class="search_input"
            v-model="queryParam.hysmc"
            placeholder="会议室名称"
          />
        </a-col>
        <a-col :md="3" :sm="3">
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons" >
            <a-button class="search_btn search_query" style="width:calc(50%-3px)" type="primary" @click="searchQuery" >查询</a-button>
            <a-button class="search_btn" style="margin-left: 5px; width:calc(50%-3px)" @click="searchReset" >重置</a-button>
          </span>
        </a-col>
      </a-row>
      <a-row>
        <a-col><span class="Tips">可根据任意搜索框内容进行检索</span></a-col>
      </a-row>
    </div>
    <!-- table区域 -->
    <div class="info_table">
      <a-table
        ref="table"
        rowKey="id"
        size="middle"
        :columns="columns"
        :data-source="dataSource"
        :pagination="ipagination"
        :loading="loading"
        @change="handleTableChange"
        >
          <span slot="action" slot-scope="text, record">
            <a v-if="!record.sfqx" @click="cancel(record)">取消</a>
            <a v-else style="color:#ccc;">取消</a>
            <a-divider type="vertical" />
            <a v-if="!record.sfqd" @click="signIn(record)">签到</a>
            <a v-else style="color:#ccc;">签到</a>
            <a-divider type="vertical" />
            <a @click="extend(record)">延长</a>
            <a-divider type="vertical" />
            <a v-if="!record.sftqjs" @click="overEarly(record)">提前结束</a>
            <a v-else style="color:#ccc;">提前结束</a>
          </span>
          <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
      </a-table>
    </div>
    <a-modal
      title='延长会议'
      :visible="visible"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel">关闭</a-button>
        <a-button class="okBtn" type="primary" key="confirm" @click="handleOk">添加</a-button>
      </template>
      <a-form>
          <a-form-item
            label="延迟时间"
            :label-col="labelCol"
            :wrapper-col="wrapperCol">
            <a-input v-model.number="min" placeholder="请输入延迟时间" suffix="分钟(min)"  @change="minChange($event)"/>
          </a-form-item>
        </a-form>
    </a-modal>
  </div>
</template>
<script>
import { JeecgListMixin } from '@/views/mixins/mixin'
import Vue from 'vue'
import { USER_INFO } from "@/store/mutation-types"
// import axios from 'axios'
import moment from 'moment'
import { getAction } from '@/api/manage'
import { MESNAC_MEETING_RESERVE_URL } from '@/views/center/modules/config'

export default {

  mixins:[JeecgListMixin],
  data() {
    return {
      newUrl: MESNAC_MEETING_RESERVE_URL,
      workNo:Vue.ls.get(USER_INFO).workNo,
      adminURL: window._CONFIG['adminURL'],
      queryParam: {employeeNo: Vue.ls.get(USER_INFO).workNo},
      extraParam: { employeeNo: Vue.ls.get(USER_INFO).workNo },
      disableMixinCreated: true,
      visible: false,
      record:{},
      min:'',
      maxJgsj:'',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      columns: [
        {
          title: '会议主题',
          align: "center",
          dataIndex: 'hyzt'
        },
        {
          title: '会议室名称',
          align: "center",
          dataIndex: 'hysmc'
        },
        {
          title: '预定开始日期',
          align: "center",
          dataIndex: 'ydksrq'
        },
        {
          title: '预定结束日期',
          align: "center",
          dataIndex: 'ydjsrq'
        },
        {
          title: '申请人',
          align: "center",
          dataIndex: 'creater'
        },
        {
          title: '使用人',
          align: "center",
          dataIndex: 'syr'
        },
        {
          title: '流程状态',
          align: "center",
          dataIndex: 'sequenceStatus'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: {
        list:'/api/public/api/meeting/getOwnApply'
      }
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    // 加载数据
    loadData() {
      var params = this.getQueryParams()
      getAction('/api/public/api/meeting/getOwnApply', params).then(res => {
        console.log('res: ', res);
        this.dataSource = res.data
      })
      // axios({
      //   method:'get',
      //   url:`${this.adminURL}:8080/api/public/api/meeting/getOwnApply`,
      //   params:params,
      // }).then(res => {
      //   this.dataSource = res.data.data
      // })
    },
    // 取消会议
    cancel(record) {
      console.log('record: ', record);
      this.$confirm({
        title:'取消会议',
        className:'modal',
        content:'确定要取消会议吗?',
        onOk:() => {
          getAction(this.newUrl.cancelMeetingRoomReserveProcess, {workNo:this.workNo, workflowInstanceId:record.workflowInstanceId}).then(res => {
            console.log('cancelMeetingRoomReserveProcess res: ', res);
            if (res.success) {
              this.loadData()
            } else {
              this.$message.error("取消失败：" + res.message)
            }
          })
          // getAction('/api/public/api/meeting/cancel', {workflowInstanceId:record.workflowInstanceId}).then(res => {
          //   console.log('res: ', res);
          //   this.loadData()
          // })

          // axios({
          //   method:'get',
          //   url:`${this.adminURL}:8080/api/public/api/meeting/cancel`,
          //   params:{workflowInstanceId:record.workflowInstanceId},
          // }).then(res => {
          //   console.log('res: ', res);
          //   this.loadData()
          // })
        }
      })
    },
    // 签到会议
    signIn(record) {
      let starTime = moment(record.ydksrq)
      let nowTime = moment()
      let interval = starTime.diff(nowTime, 'minute')
      if( interval>= -15 && interval <=15) {
        this.$confirm({
          title:'签到会议',
          className:'modal',
          content:'确定要签到会议吗?',
          type: 'success',
          onOk:() => {
            getAction('/api/public/api/meeting/meetingSign', {workflowInstanceId:record.workflowInstanceId}).then(res => {
              console.log('res: ', res);
              this.loadData()
            })
            // axios({
            //   method:'get',
            //   url:`${this.adminURL}:8080/api/public/api/meeting/meetingSign`,
            //   params:{workflowInstanceId:record.workflowInstanceId},
            // }).then(res => {
            //   console.log('res: ', res);
            //   this.loadData()
            // })
          }
        })
      } else {
        this.$message.warning(interval> 15 ? '还未到签到时间！' : '以过签到时间！')
      }
    },
    // 延长会议
    extend(record) {
      getAction('/api/public/api/meeting/lengthen', {workflowInstanceId: record.workflowInstanceId}).then(res => {
        console.log('res: ', res);
        if(res.msg == 'filed') {
          this.$message.warning('会议还未开始，无法延长时间!')
          return
        }
        if(res.flag) {
          if(res.jgsj == 0) {
            this.record = record
            this.visible = true
          }else {
            this.$confirm({
              title:'延长时间',
              content:'最多可延长'+res.jgsj+'分钟',
              onOk:() => {
                this.maxJgsj = res.jgsj
                this.record = record
                this.visible = true
              }
            })
          }
        }else {
          this.$message.warning('预约已满，无法延长会议！')
        }
      })
      // axios({
      //   method:'get',
      //   url:`${this.adminURL}:8080/api/public/api/meeting/lengthen`,
      //   params:{workflowInstanceId: record.workflowInstanceId},
      // }).then(res => {
      //   if(res.data.msg == 'filed') {
      //     this.$message.warning('会议还未开始，无法延长时间!')
      //     return
      //   }
      //   if(res.data.flag) {
      //     console.log(res.data.jgsj)
      //     if(res.data.jgsj == 0) {
      //       this.record = record
      //       this.visible = true
      //     }else {
      //       this.$confirm({
      //         title:'延长时间',
      //         content:'最多可延长'+res.data.jgsj+'分钟',
      //         onOk:() => {
      //           this.maxJgsj = res.data.jgsj
      //           this.record = record
      //           this.visible = true
      //         }
      //       })
      //     }
      //   }else {
      //     this.$message.warning('预约已满，无法延长会议！')
      //   }
      // })
    },
    //
    minChange(e) {
      this.min  = e.target.value.replace(/[^\d]/g,'')
      if(this.min >120) {
        this.$message.warning('最大延长时间为120分钟!')
        this.min = ''
      }
      console.log('this.min: ', this.min);

    },
    handleCancel() {
      this.visible = false
      this.min = ''
      this.maxJgsj = ''
      this.record = {}
    },
    handleOk() {
      if(!this.min) {
        this.$message.warning('请输入延长时间！')
        return
      }
      if(this.maxJgsj && this.min > this.maxJgsj) {
        this.$message.warning('超出最长预约时间！')
        this.min = this.maxJgsj
        return
      } else {
        getAction('/api/public/api/meeting/updateMeeting', {workflowInstanceId: this.record.workflowInstanceId, min:this.min}).then(res => {
          console.log('res: ', res);
          if(res.msg == 'success') {
            this.$message.success('会议延长成功！')
            this.loadData()
          }  else {
            this.$message.warning('会议延长失败！')
          }
          this.loadData()
        }).finally(()=> {
          this.handleCancel()
        })
        // axios({
        //   method:'get',
        //   url:`${this.adminURL}:8080/api/public/api/meeting/updateMeeting`,
        //   params:{workflowInstanceId: this.record.workflowInstanceId, min:this.min},
        // }).then(res => {
        //   console.log('res: ', res);
        //   if(res.data.msg == 'success') {
        //     this.$message.success('会议延长成功！')
        //     this.loadData()
        //   }  else {
        //     this.$message.warning('会议延长失败！')
        //   }
        //   this.loadData()
        // }).finally(() => {
        //   this.handleCancel()
        // })
      }

    },
    // 提前结束和取消
    overEarly(record) {
      let startTime = moment(record.ydksrq)
      let endTime = moment(record.ydjsrq)
      let nowTime = moment()
      if(startTime.diff(nowTime) < 0 && endTime.diff(nowTime) > 0) {
        this.$confirm({
          title:'提前结束和取消',
          className:'modal',
          content:'确定要提前结束和取消吗?',
          onOk:() => {
            getAction('/api/public/api/meeting/overEarly', {workflowInstanceId:record.workflowInstanceId, ydjsrq:record.ydjsrq}).then(res => {
              console.log('res: ', res);
              if(res.success) {
                this.loadData()
              }
            })
            // axios({
            //   method:'get',
            //   url:`${this.adminURL}:8080/api/public/api/meeting/overEarly`,
            //   params:{workflowInstanceId:record.workflowInstanceId, ydjsrq:record.ydjsrq},
            // }).then(res => {
            //   if(res.data.success) {
            //     this.loadData()
            //   }
            // })
          }
        })
      } else {
        this.$message.warning('会议还未开始或者已结束！')
      }

    },
    searchReset() {
      this.queryParam = {employeeNo :this.workNo}
      this.loadData()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-modal{
  .ant-modal-content{
    .ant-modal-confirm-btns{
      .ant-btn-primary{
        background: #BC0039;
      }
    }
  }
}
.info_search{
  margin-bottom: 20px;
  .Tips{
    display: inline-block;
    color: #bc0039;
    font-size: 18px;
    line-height: 30px;
  }
  /deep/.ant-col {
    .ant-calendar-picker {
      .ant-calendar-picker-input {
        height: 40px;
        border: 1px solid rgba(188, 0, 57, 100);
        border-radius: 10px;
        color: rgba(188, 0, 57, 100);
        font-size: 18px;
        &::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          font-size: 18px;
          color: rgba(188, 0, 57, 100);
        }
      }
      .anticon {
        color: #bc0039;
      }
    }
  }
  .search_input {
    height: 40px;
    border: 1px solid rgba(188, 0, 57, 100);
    border-radius: 10px;
    color: rgba(188, 0, 57, 100);
    font-size: 18px;
    &::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      font-size: 18px;
      color: rgba(188, 0, 57, 100);
    }
  }
  .search_btn {
    width: 67px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: #bc0039;
    border: 1px solid #bc0039;
    border-radius: 10px;
    padding: 0px;
  }
  .search_query {
    background-color: #ffeded;
  }
}
.info_table{
  /deep/.ant-table {
    color: #101010;
  }
  /deep/.ant-table-placeholder {
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-table-tbody{
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-pagination {
    .ant-pagination-next, .ant-pagination-prev {
        .ant-pagination-item-link{
            &:hover{
                color: #BC0039;
            }
        }
    }
    .ant-pagination-next:focus .ant-pagination-item-link{
        color: #BC0039;
    }
    .ant-pagination-item{
        a:hover{
            color: #BC0039;
        }
    }
    .ant-pagination-item-active{
        border-color: #BC0039;
        a{color: #BC0039;}
    }
  }
}
</style>


