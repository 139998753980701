<template>
  <div>
    <div class="reserve_title">预定情况</div>
    <!-- 搜索区域 -->
    <div class="info_search">
      <a-row :gutter="24">
        <a-col :md="9" :sm="9">
          <j-date
              v-model="queryParam['startTime']"
              placeholder="请选择开始日期"
              :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
              date-format="YYYY-MM-DD HH:mm"
              style="min-width: 120px; width:calc(50% - 15px);"
          ></j-date>
          <span style="color:#BC0039" class="group-query-strig">&nbsp;~&nbsp;</span>
          <j-date
              v-model="queryParam['endTime']"
              :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
              date-format="YYYY-MM-DD HH:mm"
              placeholder="请选择结束日期"
              style="min-width: 120px; width:calc(50% - 15px);"
          ></j-date>
        </a-col>
        <a-col :md="4" :sm="4">
          <a-input
            class="search_input"
            v-model="queryParam.creater"
            placeholder="申请人"
          />
        </a-col>
        <a-col :md="4" :sm="4">
          <a-input
            class="search_input"
            v-model="queryParam.syr"
            placeholder="使用人"
          />
        </a-col>
<!--        <a-col :md="4" :sm="4">-->
<!--          <a-input-->
<!--            class="search_input"-->
<!--            v-model="queryParam.hysmc"-->
<!--            placeholder="会议室名称"-->
<!--          />-->
<!--        </a-col>-->
        <a-col :md="4" :sm="4">
          <a-select v-model="queryParam.ssyq" placeholder="会议室所属园区" style="width: 150px;" :allowClear="true">
            <a-select-option
              v-for="(meetingRoomLocation,meetingRoomLocationIndex) in meetingRoomLocationList"
              :key="meetingRoomLocationIndex"
              :value="meetingRoomLocation">
              {{ meetingRoomLocation }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :md="3" :sm="3">
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons" >
            <a-button class="search_btn search_query " style="width:calc(50%-3px)" type="primary" @click="searchQuery" >查询</a-button>
            <a-button class="search_btn" style="margin-left: 5px; width:calc(50%-3px)" @click="searchReset" >重置</a-button>
          </span>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <span class="Tips">可根据任意搜索框内容进行检索</span>
        </a-col>
      </a-row>
    </div>
    <!-- table区域 -->
    <div class="info_table">
      <a-table
          ref="table"
          rowKey="id"
          size="middle"
          :scroll="{x:true}"
          :columns="isAdmin? columnsAdmin :columns"
          :data-source="dataSource"
          :pagination="ipagination"
          :loading="loading"
          @change="handleTableChange"
          >
        <span slot="action" slot-scope="record">
          <a v-if="record.meetingRoomAdminWorkNo == workNo" @click="handleView(record)">查看</a>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import { JeecgListMixin } from '@/views/mixins/mixin'
import Vue from 'vue'
import { USER_INFO } from "@/store/mutation-types"
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
import { MESNAC_MEETING_RESERVE_URL } from '@/views/center/modules/config'

export default {
  mixins:[JeecgListMixin],
  data() {
    return {
      adminURL: window._CONFIG['adminURL'],
      workNo:Vue.ls.get(USER_INFO).workNo,
      userInfo: Vue.ls.get(USER_INFO),
      newUrl: MESNAC_MEETING_RESERVE_URL,
      isAdmin: false,
      columns: [
        {
          title: '会议室名称',
          align: "center",
          dataIndex: 'hysmc'
        },
        {
          title: '预定开始日期',
          align: "center",
          dataIndex: 'ydksrq'
        },
        {
          title: '预定结束日期',
          align: "center",
          dataIndex: 'ydjsrq'
        },
        {
          title: '申请人',
          align: "center",
          dataIndex: 'creater'
        },
        {
          title: '使用人',
          align: "center",
          dataIndex: 'syr'
        },
        {
          title: '流程状态',
          align: "center",
          dataIndex: 'sequenceStatus'
        }
      ],
      columnsAdmin: [
        {
          title: '会议室名称',
          align: "center",
          key: 'hysmc',
          dataIndex: 'hysmc'
        },
        {
          title: '预定开始日期',
          align: "center",
          key: 'ydksrq',
          dataIndex: 'ydksrq'
        },
        {
          title: '预定结束日期',
          align: "center",
          key: 'ydjsrq',
          dataIndex: 'ydjsrq'
        },
        {
          title: '申请人',
          align: "center",
          key: 'creater',
          dataIndex: 'creater'
        },
        {
          title: '使用人',
          align: "center",
          key: 'syr',
          dataIndex: 'syr'
        },
        {
          title: '流程状态',
          align: "center",
          key: 'sequenceStatus',
          dataIndex: 'sequenceStatus'
        },
        {
          title:'操作',
          align:'center',
          key: 'action',
          scopedSlots: {customRender: 'action'}
        },
      ],
      disableMixinCreated: true,
      url: {
        list: MESNAC_MEETING_RESERVE_URL.getMeetingRoomReserveWorkflowInfoList
      },
      meetingRoomLocationList : [],
    }
  },
  created() {
    this.checkAdmin()
    this.getMeetingRoomLocationList()
    this.loadData()
  },
  methods: {
    // 检测管理员
    checkAdmin(){
      getAction(this.newUrl.checkUserIsAdmin, {workNo:this.workNo}).then(res => {
        console.log('checkAdmin res: ', res);
        if (res.success){
          this.isAdmin = res.result
        }else{
          this.$error({title: '检测管理员权限异常', content: res.message})
        }
      })
    },
    // 获取会议室园区列表
    getMeetingRoomLocationList(){
      getAction(this.newUrl.getMeetingRoomLocationListApi, {}).then(res => {
        console.log('getMeetingRoomLocationList res: ', res);
        if (res.success){
          this.meetingRoomLocationList = res.result
          console.log("meetingRoomLocationList:", this.meetingRoomLocationList)
        }else{
          this.$error({title: '获取会议室园区列表异常', content: res.message})
        }
      })
    },
    loadData() {
      this.loading = true
      var params = this.getQueryParams()
      params.userName = this.userInfo.username
      getAction(this.newUrl.getMeetingRoomReserveWorkflowInfoList, params).then(res => {
        console.log('getMeetingRoomReserveWorkflowInfoList res: ', res);
        this.dataSource = res.result.records
        this.ipagination.current = res.result.current
        this.ipagination.total = res.result.total
        this.loading = false
      })
    },
    searchReset() {
      this.queryParam = {}
      this.loadData()
    },
    getImgView(text){
      if(text && text.indexOf(",")>0){
        text = text.substring(0,text.indexOf(","))
      }
      return getFileAccessHttpUrl(text)
    },
    handleView(record){
      var tempwindow = window.open('_blank')
      tempwindow.location = record.meetingRoomReserveDetailUrl
    }
  }
}
</script>
<style lang="less" scoped>
.reserve_title{
  margin-bottom:20px;
  font-size: 22px;
  color: #101010;
}
.info_search{
  margin-bottom: 20px;
  .Tips{
    display: inline-block;
    color: #bc0039;
    font-size: 18px;
    line-height: 30px;
  }
  /deep/.ant-col {
    .ant-calendar-picker {
      .ant-calendar-picker-input {
        height: 40px;
        border: 1px solid rgba(188, 0, 57, 100);
        border-radius: 10px;
        color: rgba(188, 0, 57, 100);
        font-size: 18px;
        &::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          font-size: 18px;
          color: rgba(188, 0, 57, 100);
        }
      }
      .anticon {
        color: #bc0039;
      }
    }
  }
  .search_input {
    height: 40px;
    border: 1px solid rgba(188, 0, 57, 100);
    border-radius: 10px;
    color: rgba(188, 0, 57, 100);
    font-size: 18px;
    &::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      font-size: 18px;
      color: rgba(188, 0, 57, 100);
    }
  }
  .search_btn {
    width: 67px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: #bc0039;
    border: 1px solid #bc0039;
    border-radius: 10px;
    padding: 0px;
  }
  .search_query {
    background-color: #ffeded;
  }
}
.info_table{
  /deep/.ant-table {
    color: #101010;
    .ant-table-thead > tr > th{
      color: #101010;
    }
  }
  /deep/ .ant-table th { white-space: nowrap; }
  /deep/ .ant-table td { white-space: nowrap; }
  /deep/.ant-table-placeholder {
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-table-tbody{
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-pagination {
    .ant-pagination-next, .ant-pagination-prev {
        .ant-pagination-item-link{
            &:hover{
                color: #BC0039;
            }
        }
    }
    .ant-pagination-next:focus .ant-pagination-item-link{
        color: #BC0039;
    }
    .ant-pagination-item{
        a:hover{
            color: #BC0039;
        }
    }
    .ant-pagination-item-active{
        border-color: #BC0039;
        a{color: #BC0039;}
    }
  }
}


/deep/ .ant-select-selection {
  border: 1px solid #bc0039;
  color: #BC0039;
  height: 2.083vw;
  border-radius: 10px;
  font-size: 0.938vw;

  .ant-select-selection__placeholder, .ant-select-search__field__placeholder{
    color: #BC0039;
  }
}
</style>


