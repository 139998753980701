<template>
  <div>
    <div class="reserve_title"> {{isAdmin ? '预定情况':'会议室详情'}}</div>
    <!-- 搜索区域 -->
    <div class="info_search">
      <a-row :gutter="24">
        <a-col v-if="isAdmin"  :md="4" :sm="4">
          <a-input
            class="search_input"
            v-model="queryParam.syr"
            placeholder="使用人"
          />
        </a-col>
        <a-col :md="4" :sm="4">
          <a-input
            class="search_input"
            v-model="queryParam.hysmc"
            placeholder="会议室名称"
          />
        </a-col>
        <a-col :md="5" :sm="5">
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons" >
            <a-button class="search_btn search_query" type="primary" @click="searchQuery" >查询</a-button>
            <a-button class="search_btn" style="margin-left: 8px" @click="searchReset" >重置</a-button>
          </span>
        </a-col>
      </a-row>
    </div>
    <!-- table区域 -->
    <div class="info_table">
      <a-table 
          ref="table"
          rowKey="id"
          size="middle"
          :scroll="{x:true}"
          :columns="isAdmin? columnsAdmin :columns" 
          :data-source="dataSource"
          :pagination="ipagination"
          :loading="loading"
          @change="handleTableChange"
          >
          <template v-if="!isAdmin" slot="imgSlot" slot-scope="text">
            <span v-if="!text" style="font-size: 12px;font-style: italic;">无图片</span>
            <img v-else :src="getImgView(text)" height="25px" alt="" style="max-width:80px;font-size: 12px;font-style: italic;"/>
          </template>
          <span slot="action" slot-scope="text, record">
            <a @click="handleView(record)">查看</a>
          </span>
          <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
      </a-table>
    </div>
    <hysgl-modal ref="FormModal" />
  </div>
</template>
<script>
import { JeecgListMixin } from '@/views/mixins/mixin'
import Vue from 'vue'
import { USER_INFO } from "@/store/mutation-types"
import { getAction, getFileAccessHttpUrl } from '@/api/manage'
import  HysglModal  from './HysglModal'
// import axios from 'axios'

export default {
  mixins:[JeecgListMixin],
  components: {
    HysglModal
  },
  data() {
    return {
      adminURL: window._CONFIG['adminURL'],
      workNo:Vue.ls.get(USER_INFO).workNo,
      queryParam: {employeeNo :Vue.ls.get(USER_INFO).workNo },
      isAdmin: false,
      columns: [
        {
          title: '会议室名称',
          align: "center",
          dataIndex: 'hysmc'
        },
        {
          title:'会议室地点',
          align:"center",
          dataIndex: 'hysdd'
        },
        {
          title:'功能',
          align:"center",
          dataIndex: 'sbmx'
        },
        {
          title:'容纳人数',
          align:"center",
          dataIndex: 'rnrs'
        },
        {
          title:'图片',
          align:"center",
          dataIndex: 'hystp',
          scopedSlots: {customRender: 'imgSlot'}
        },
        {
          title:'操作',
          align:'center',
          dataIndex:'action',
          scopedSlots: {customRender: 'action'}
        }
      ],
      columnsAdmin: [
        {
          title: '会议室名称',
          align: "center",
          dataIndex: 'hysmc'
        },
        {
          title: '预定开始日期',
          align: "center",
          dataIndex: 'ydksrq'
        },
        {
          title: '预定结束日期',
          align: "center",
          dataIndex: 'ydjsrq'
        },
        {
          title: '申请人',
          align: "center",
          dataIndex: 'fqr'
        },
        {
          title: '使用人',
          align: "center",
          dataIndex: 'syr'
        },
      ],
      disableMixinCreated: true,
      url: {
        list:'/api/public/api/meeting/getInstance'
      }
    }
  },
  created() {
    this.getAdmin()
  },
  methods: {
    getAdmin() {
      this.loadData()
    },
    loadData() {
      var params = this.getQueryParams()
      console.log('this.isAdmin: ', this.isAdmin);
      if(this.isAdmin) {
        getAction('/api/public/api/meeting/getInstance', params).then(res => {
          console.log('res: ', res);
          this.dataSource = JSON.parse(res.msg)
        })
      } else {
        getAction('/mesnac/zmehysgl/meetingroom/selectAll', params).then(res => {
          if(res.success) {
            this.dataSource = res.result
          }
        })
      }
    },
    handleView(record) {
      this.$refs.FormModal.edit(record)
      // console.log('record: ', record);

    },
    searchReset() {
      this.queryParam = {employeeNo :this.workNo}
      this.loadData()
    },
    getImgView(text){
      if(text && text.indexOf(",")>0){
        text = text.substring(0,text.indexOf(","))
      }
      return getFileAccessHttpUrl(text)
    },
  }
}
</script>
<style lang="less" scoped>
.reserve_title{
  margin-bottom:20px;
  font-size: 22px;
  color: #101010;
}
.info_search{
  margin-bottom: 20px;
  /deep/.ant-col {
    .ant-calendar-picker {
      .ant-calendar-picker-input {
        height: 40px;
        border: 1px solid rgba(188, 0, 57, 100);
        border-radius: 10px;
        color: rgba(188, 0, 57, 100);
        font-size: 18px;
        &::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          font-size: 18px;
          color: rgba(188, 0, 57, 100);
        }
      }
      .anticon {
        color: #bc0039;
      }
    }
  }
  .search_input {
    height: 40px;
    border: 1px solid rgba(188, 0, 57, 100);
    border-radius: 10px;
    color: rgba(188, 0, 57, 100);
    font-size: 18px;
    &::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      font-size: 18px;
      color: rgba(188, 0, 57, 100);
    }
  }
  .search_btn {
    width: 67px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: #bc0039;
    border: 1px solid #bc0039;
    border-radius: 10px;
    padding: 0px;
  }
  .search_query {
    background-color: #ffeded;
  }
}
.info_table{
  /deep/.ant-table {
    color: #101010;
    .ant-table-thead > tr > th{
      color: #101010;
    }
  }
  /deep/ .ant-table th { white-space: nowrap; }
  /deep/ .ant-table td { white-space: nowrap; }
  /deep/.ant-table-placeholder {
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-table-tbody{
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }
  /deep/.ant-pagination {
    .ant-pagination-next, .ant-pagination-prev {
        .ant-pagination-item-link{
            &:hover{
                color: #BC0039;
            }
        }
    }
    .ant-pagination-next:focus .ant-pagination-item-link{
        color: #BC0039;
    }
    .ant-pagination-item{
        a:hover{
            color: #BC0039;
        }
    }
    .ant-pagination-item-active{
        border-color: #BC0039;
        a{color: #BC0039;}
    }
  }
}
</style>


