
export const MESNAC_MEETING_RESERVE_URL = {
  // 获取会议室信息
  getMeetingRoomInfo: '/mesnac/meetingReserve/getMeetingRoomInfo',
  // 获取会议室预定信息
  getMeetingRoomReserveInfo: '/mesnac/meetingReserve/getMeetingRoomReserveInfo',
  // 取消会议室申请
  cancelMeetingRoomReserveProcess: '/mesnac/meetingReserve/cancelMeetingRoomReserveProcess',
  // 检测用户是否为会议室管理员
  checkUserIsAdmin: '/mesnac/meetingReserve/checkUserIsAdmin',
  // 获取会议室园区列表
  getMeetingRoomLocationListApi: '/mesnac/meetingReserve/getMeetingRoomLocationList',
  // 获取会议室预约工作流列表
  getMeetingRoomReserveWorkflowInfoList : '/mesnac/meetingReserve/getMeetingRoomReserveWorkflowInfoList',
  // 会议室预约时间修改
  modifyMeetingRoomReserveInfo: '/mesnac/meetingReserve/modifyMeetingRoomReserveInfo'
}
