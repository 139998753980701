<template>
  <div class="meeting_warp">
    <div class="meeting_content">
<!--      <div class="img_warp">-->
<!--          <img src="~@/assets/img/expect.png" alt="">-->
<!--          <span>暂未开通服务，敬请期待</span>-->
<!--      </div>-->
       <a-tabs default-active-key="1">
        <a-tab-pane key="1">
          <span slot="tab">会议室预定</span>
          <div class="meeting_reserve">
            <meeting-reserve/>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">我的预定</span>
          <div class="my_reserve">
            <div class="reserve_title">我的预定</div>
            <my-reserve/>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">预定情况</span>
         <div class="reserve_info">
           <meetingReserveInfo/>
         </div>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">会议室清单</span>
         <div class="reserve_info">
           <meetingl />
         </div>
        </a-tab-pane>
         <a-tab-pane key="5" v-if="isAdmin">
           <span slot="tab">修改预定</span>
           <div class="reserve_info">
             <modifyMeetingReserveInfo/>
           </div>
         </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import myReserve from './MyReserve'
import meetingReserve from './MeetingReserve'
import meetingl from './MeetingL'
import meetingReserveInfo from './MeetingReserveInfo'
import modifyMeetingReserveInfo from './ModifyMeetingReserveInfo'

import { USER_INFO } from "@/store/mutation-types"
import { getAction } from '@/api/manage'
import { MESNAC_MEETING_RESERVE_URL } from '@/views/center/modules/config'
// import axios from 'axios'

export default {
  components: {
    myReserve,
    meetingReserve,
    meetingl,
    meetingReserveInfo,
    modifyMeetingReserveInfo
  },
  data() {
    return {
      adminURL: window._CONFIG['adminURL'],
      userInfo: Vue.ls.get(USER_INFO),
      newUrl: MESNAC_MEETING_RESERVE_URL,
      isAdmin: false,
    }
  },
  created() {
    this.checkAdmin()
  },
  methods: {
    // 检测管理员
    checkAdmin() {
      getAction(this.newUrl.checkUserIsAdmin, { workNo: this.userInfo.workNo }).then(res => {
        if (res.success) {
          this.isAdmin = res.result
        } else {
          this.$error({ title: '检测管理员权限异常', content: res.message })
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
// 修改tabs样式
/deep/.ant-tabs{
    .ant-tabs-nav-container , .ant-tabs-nav-wrap , .ant-tabs-nav-scroll{
        // background-color:rgba(188, 0, 57, 100);
        background: #fff;
        height: 70px;
        border-bottom: 1px solid #ccc;
        .ant-tabs-nav{
            width: 100%;
            margin-top:13px;
            height: 60px;
            .ant-tabs-tab{
                height: 60px;
                padding: 12px 0px;
                line-height: 30px;
                width: 10%;
                margin: 0px;
                text-align: center;
                color:#101010;
                font-size: 22px;
                &::after {
                  content: "";
                  display: inline-block;
                  height: 2px;
                  width: 100%;
                  position: absolute;
                  left:0px;
                  background: #fff;
                  bottom:2px;
                  z-index: 100;
                }
            }
            .ant-tabs-tab-active{
                height: 58px;
                color: rgba(188, 0, 57, 100);
                background: #fff;
                position: relative;
                &::after {
                  content: "";
                  display: inline-block;
                  height: 2px;
                  width: 100px;
                  position: absolute;
                  left:50%;
                  margin-left: -50px;
                  background: #BC0039;
                  bottom:2px;
                  z-index: 100;
                }
            }
            .ant-tabs-ink-bar, .ant-tabs-ink-bar-animated {
                display: none !important;
            }
            .ant-tabs-content{
                background: #fff;
            }
        }
    }
}
.meeting_warp{
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 800px;
  position: relative;
  // background: url('~@/assets/img/home_bg.png') no-repeat top center / cover;
  background: #f6f6f6;
  background-size: 100%;
  .meeting_content{
    width: 1500px;
    margin: 0 auto;
    min-height: 600px;
    background: #fff;
    .img_warp{
      margin: 0px auto;
      padding-top: 150px;
      // height: 200px;
      width: 200px;
      text-align: center;
      img{
        width: 100%;
        height: 100%;
      }
      span{
        display: inline-block;
        margin-top: 30px;
        font-size: 14px;
        color: #101010;
      }
    }
    .meeting_reserve{
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      .reserve_title{
        margin-bottom:20px;
        font-size: 22px;
        color: #101010;
      }
    }
    .my_reserve{
      background: #fff;
      padding: 20px;
      margin-bottom: 20px;
      .reserve_title{
        margin-bottom:20px;
        font-size: 22px;
        color: #101010;
      }
    }
    .reserve_info{
      background: #fff;
      padding: 20px;
      .reserve_title{
        margin-bottom:20px;
        font-size: 22px;
        color: #101010;
      }
    }
  }
}
</style>


