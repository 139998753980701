<template>
  <div>
    <a-modal
      title="会议室详情"
      :width="800"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
    <template slot="footer">
      <a-button @click="handleCancel">取消</a-button>
      <a-button class="okBtn" @click="handleOk">确定</a-button>
    </template>
    <a-spin :spinning="confirmLoading">
      <a-form>
          <a-row :gutter="10">
            <a-col :md="12" :sm="24">
              <a-form-item label="会议室名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.hysmc || '/'}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="会议室地点" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.hysdd || '/'}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="设备明细" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.sbmx || '/'}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="容纳人数" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.rnrs || '/'}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="图片" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <img class="hystpImg" :src="this.model.hystp" >
              </a-form-item>
            </a-col>
            <a-col :md="24" :sm="24">
              <a-form-item label="介绍" :labelCol="{xs: { span: 24 },sm: { span: 3 }}" :wrapperCol="{xs: { span: 24 },sm: { span: 18 },}">
                {{this.model.js || '/'}}
              </a-form-item>
            </a-col>
          </a-row>
      </a-form>
    </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible:false,
      confirmLoading: false,
      model:{},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
  },
  methods: {
    edit(record) {
      this.visible =true
      console.log('record', record)
      this.model = Object.assign({}, record)
    },
    handleCancel() {
      this.visible = false
      this.model = {}
    },
    handleOk() {
      this.handleCancel()
    }
  }
}
</script>
<style lang="less" scoped>
.hystpImg{
  width: 100px;
  height: 100px;
}
</style>


