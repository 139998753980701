<template>
  <div>
    <a-modal
      title="修改会议预定信息"
      :width="800"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
    <template slot="footer">
      <a-button :loading="confirmLoading" @click="handleCancel">取消</a-button>
      <a-button :loading="confirmLoading" class="okBtn" @click="handleOk">确定</a-button>
    </template>
    <a-spin :spinning="confirmLoading">
      <a-form ref="modifyForm">
          <a-row :gutter="10">
            <a-col :md="12" :sm="24">
              <a-form-item label="流程编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.sequenceNo}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="会议室名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.hysmc}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="申请人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.creater}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="使用人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.syr}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="预定开始日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.ydksrq}}
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="预定结束日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                {{this.model.ydjsrq}}
              </a-form-item>
            </a-col>
          </a-row>
          <a-divider>修改后日期</a-divider>
          <a-row :gutter="10">
            <a-col :md="12" :sm="24">
              <a-form-item label="修改开始日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <j-date
                  v-model="modifyModel.ydksrq"
                  :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
                  date-format="YYYY-MM-DD HH:mm"
                  placeholder="请选择开始日期"
                  style="min-width: 180px; width:calc(50% - 15px);"
                ></j-date>
              </a-form-item>
            </a-col>
            <a-col :md="12" :sm="24">
              <a-form-item label="修改结束日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <j-date
                  v-model="modifyModel.ydjsrq"
                  :showTime="{defaultValue:moment('00:00:00', 'HH:mm:ss')}"
                  date-format="YYYY-MM-DD HH:mm"
                  placeholder="请选择结束日期"
                  style="min-width: 180px; width:calc(50% - 15px);"
                ></j-date>
              </a-form-item>
            </a-col>
          </a-row>
      </a-form>
    </a-spin>

    </a-modal>
  </div>
</template>

<script>
import { JeecgListMixin } from '@/views/mixins/mixin'
import Vue from 'vue'
import { httpAction } from '@/api/manage'
import { MESNAC_MEETING_RESERVE_URL } from '@/views/center/modules/config'
import { USER_INFO } from '@/store/mutation-types'

export default {
  mixins:[JeecgListMixin],
  data() {
    return {
      disableMixinCreated:true,
      visible:false,
      confirmLoading: false,
      url: MESNAC_MEETING_RESERVE_URL,
      userInfo: Vue.ls.get(USER_INFO),
      model:{},
      modifyModel:{},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
  },
  methods: {
    edit(record) {
      this.visible =true
      console.log('record', record)

      this.model = Object.assign({}, record)
      this.modifyModel = Object.assign({}, record)
    },
    handleCancel() {
      this.visible = false
      this.model = {}
      this.modifyModel = {}
    },
    handleOk() {
      // todo 需要增加修改数据
      console.log("888888:",this.modifyModel)
      if (this.modifyModel.ydksrq === undefined || this.modifyModel.ydksrq === null || this.modifyModel.ydksrq === ''){
        this.$message.error('修改后开始日期不能为空')
        return
      }

      if (this.modifyModel.ydjsrq === undefined || this.modifyModel.ydjsrq === null || this.modifyModel.ydjsrq === ''){
        this.$message.error('修改后结束日期不能为空')
        return
      }
      console.log("userInfo:",this.userInfo)

      let that = this
      this.$confirm({
        title: '确认',
        content: '确认修改会议预约时间?',
        onOk: function() {
          that.confirmLoading = true

          that.modifyModel.processId = that.modifyModel.id
          that.modifyModel.userName = that.userInfo.username
          console.log("modifyModel:",that.modifyModel)
          httpAction(that.url.modifyMeetingRoomReserveInfo, that.modifyModel, 'post').then(res => {
            that.confirmLoading = false
            if (res.success) {
              that.$message.success('修改成功')

              that.handleCancel()
              that.$emit('loadData');
            } else {
              that.$message.error('修改失败[' + res.message + ']')
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.hystpImg{
  width: 100px;
  height: 100px;
}
</style>


