/* eslint-disable prefer-spread */
/* eslint-disable prefer-rest-params */
import { duplicateCheck } from '@/api/login'
/**
 * Determines whether `val` is null or undefined or empty string.
 */
export function isNUE(val) {
    if (typeof val === 'string') val = val.trim()
    return val == null || val === ''
  }
  
  /**
   * Determines whether `n` is numeric.
   */
  export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }
  
  /**
   * 触发 window.resize
   */
  export function triggerWindowResizeEvent() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
  }
  
  /**
   * 过滤对象中为空的属性
   * @param obj
   * @returns {*}
   */
  export function filterObj(obj) {
    if (!(typeof obj == 'object')) {
      return
    }
  
    for (var key in obj) {
      if ({}.hasOwnProperty.call(obj, key) &&
        (obj[key] == null || obj[key] == undefined || obj[key] === '')) {
        delete obj[key]
      }
    }
    return obj
  }
  
  /**
   * 时间格式化
   * @param value
   * @param fmt
   * @returns {*}
   */
  export function formatDate(value, fmt) {
    const regPos = /^\d+(\.\d+)?$/
    if (regPos.test(value)) {
      // 如果是数字
      const getDate = new Date(value)
      const o = {
        'M+': getDate.getMonth() + 1,
        'd+': getDate.getDate(),
        'h+': getDate.getHours(),
        'm+': getDate.getMinutes(),
        's+': getDate.getSeconds(),
        'q+': Math.floor((getDate.getMonth() + 3) / 3),
        'S': getDate.getMilliseconds()
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (const k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
      }
      return fmt
    } else {
      // TODO
      value = value.trim()
      return value.substr(0, fmt.length)
    }
  }
  
  /**
   * 使用JSON相关方法克隆对象
   * @param obj 被克隆的对象
   * @return 克隆后的对象
   */
  export function cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj))
  }
  
  /**
   * 随机生成数字
   *
   * 示例：生成长度为 12 的随机数：randomNumber(12)
   * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
   *
   * @param1 最小值 | 长度
   * @param2 最大值
   * @return int 生成后的数字
   */
  export function randomNumber() {
    // 生成 最小值 到 最大值 区间的随机数
    const random = (min, max) => {
      return Math.floor(Math.random() * (max - min + 1) + min)
    }
    if (arguments.length === 1) {
      const [length] = arguments
      // 生成指定长度的随机数字，首位一定不是 0
      const nums = [...Array(length).keys()].map(i => (i > 0 ? random(0, 9) : random(1, 9)))
      return parseInt(nums.join(''))
    } else if (arguments.length >= 2) {
      const [min, max] = arguments
      return random(min, max)
    } else {
      return Number.NaN
    }
  }
  
  /**
   * 随机生成字符串
   * @param length 字符串的长度
   * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
   * @return string 生成的字符串
   */
  export function randomString(length, chats) {
    if (!length) length = 1
    if (!chats) chats = '0123456789qwertyuioplkjhgfdsazxcvbnm'
    let str = ''
    for (let i = 0; i < length; i++) {
      const num = randomNumber(0, chats.length - 1)
      str += chats[num]
    }
    return str
  }
  
  /**
   * 下划线转驼峰
   * @param string
   * @returns {*}
   */
  export function underLine2CamelCase(string) {
    return string.replace(/_([a-z])/g, function (all, letter) {
      return letter.toUpperCase()
    })
  }
  
  /**
   * 树形结构转为数组形式
   * @param {[]} tree
   * @param {string} key 子节点的key，默认children
   * @returns {[]}
   */
  export function treeToArr(tree = [], key = 'children') {
    tree = JSON.parse(JSON.stringify(tree))
    const itr = (cont, item) => {
      cont.push(item)
      if (item[key] && item[key].length > 0) {
        item[key].reduce(itr, cont)
        delete item[key]
      }
      return cont
    }
    return tree.reduce(itr, [])
  }
  
  /**
   * 下载文件
   * **非同源文件会在新标签页打开**
   * @param {string} url
   * @param {string} name
   */
  export function downloadFile(url, name = '') {
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.rel = 'noopener noreferrer'
    a.style.display = 'none'
    console.log('name: ', name)
    a.setAttribute('download', name)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
  
  /**
   * 下载文件 blob保存方式
   * @param {Blob} data
   * @param {string} fileName
   */
  export function saveBlob(data, fileName = '') {
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data]), fileName)
    } else {
      const url = window.URL.createObjectURL(new Blob([data]))
      downloadFile(url, fileName)
      window.URL.revokeObjectURL(url)
    }
  }
  
  /**
   * 简单实现防抖方法
   *
   * 防抖(debounce)函数在第一次触发给定的函数时，不立即执行函数，而是给出一个期限值(delay)，比如100ms。
   * 如果100ms内再次执行函数，就重新开始计时，直到计时结束后再真正执行函数。
   * 这样做的好处是如果短时间内大量触发同一事件，只会执行一次函数。
   *
   * @param fn 要防抖的函数
   * @param delay 防抖的毫秒数
   * @returns {Function}
   */
  export function simpleDebounce(fn, delay = 100) {
    let timer = null
    return function () {
      const args = arguments
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        fn.apply(null, args)
      }, delay)
    }
  }
  
  /**
   * 不用正则的方式替换所有值
   * @param text 被替换的字符串
   * @param checker  替换前的内容
   * @param replacer 替换后的内容
   * @returns {String} 替换后的字符串
   */
  export function replaceAll(text, checker, replacer) {
    const lastText = text
    text = text.replace(checker, replacer)
    if (lastText !== text) {
      return replaceAll(text, checker, replacer)
    }
    return text
  }

  /**
 * 重复值验证工具方法
 *
 * 使用示例：
 * { validator: (rule, value, callback) => validateDuplicateValue('sys_fill_rule', 'rule_code', value, this.model.id, callback) }
 *
 * @param tableName 被验证的表名
 * @param fieldName 被验证的字段名
 * @param fieldVal 被验证的值
 * @param dataId 数据ID，可空
 * @param callback
 */
  export function validateDuplicateValue(tableName, fieldName, fieldVal, dataId, callback) {
    if (fieldVal) {
      let params = { tableName, fieldName, fieldVal, dataId }
      duplicateCheck(params).then(res => {
        res['success'] ? callback() : callback(res['message'])
      }).catch(err => {
        callback(err.message || err)
      })
    } else {
      callback()
    }
  }
  
  /**
   * 获取事件冒泡路径，兼容 IE11，Edge，Chrome，Firefox，Safari
   * 目前使用的地方：JEditableTable Span模式
   */
  export function getEventPath(event) {
    const { target } = event
    const path = (event.composedPath && event.composedPath()) || event.path
  
    if (path != null) {
      return (path.indexOf(window) < 0) ? path.concat(window) : path
    }
  
    if (target === window) {
      return [window]
    }
  
    const getParents = (node, memo) => {
      memo = memo || []
      const { parentNode } = node
  
      if (!parentNode) {
        return memo
      } else {
        return getParents(parentNode, memo.concat(parentNode))
      }
    }
    return [target].concat(getParents(target), window)
  }
  