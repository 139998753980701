import { getAction } from '@/api/manage'
import { filterObj } from '@/utils/util';
import moment from 'moment';
import JDate from '@/components/JDate.vue'

// import Vue from 'vue'

export const JeecgListMixin = {
  components: {
    JDate
  },
    data() {
        return {
            /* 查询条件-请不要在queryParam中声明非字符串值的属性 */
            queryParam: {},
            /* 数据源 */
            dataSource:[],
            /* 分页参数 */
            ipagination:{
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                return range[0] + "-" + range[1] + " 共" + total + "条"
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
            /* 排序参数 */
            isorter:{
                column: 'createTime',
                order: 'desc',
            },
            /* 筛选参数 */
            filters: {},
            /* table加载状态 */
            loading:false,
            /* table选中keys*/
            selectedRowKeys: [],
            /* table选中records*/
            selectionRows: [],
            /* 查询折叠 */
            toggleSearchStatus:false,
            /* 高级查询条件生效状态 */
            superQueryFlag:false,
            /* 高级查询条件 */
            superQueryParams: '',
            /** 高级查询拼接方式 */
            superQueryMatchType: 'and',
        }
    },
    created() {
        if(!this.disableMixinCreated){
          this.loadData();
          //初始化字典配置 在自己页面定义
          this.initDictConfig();
        }
    },
    methods: {
        moment,
        loadData(arg) {
            if(this.list!=undefined){
              if (!this.url.list) {
                this.$message.error("请设置url.list属性!")
                return
              }
            }
            //加载数据 若传入参数1则加载第一页的内容
            if (arg === 1) {
              this.ipagination.current = 1;
            }
            var params = this.getQueryParams();//查询条件
            this.loading = true;
            if(this.url!=undefined) {
              getAction(this.url.list, params).then((res) => {
                console.log(res)
                if (res.success) {
                  //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
                  this.dataSource = res.result.records || res.result;
                  if (res.result.total) {
                    this.ipagination.total = res.result.total;
                  }
                  //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
                }
                if (res.code === 510) {
                  // this.$message.warning(res.message)
                }
                this.loading = false;
              })
            }
        },
        handleTableChange(pagination, filters, sorter) {
          //分页、排序、筛选变化时触发
          //TODO 筛选
          if (Object.keys(sorter).length > 0) {
            this.isorter.column = sorter.field;
            this.isorter.order = "ascend" == sorter.order ? "asc" : "desc"
          }
          this.ipagination = pagination;
        },
        getQueryParams() {
          //获取查询条件
          let sqp = {}
          if(this.superQueryParams){
            sqp['superQueryParams']=encodeURI(this.superQueryParams)
            sqp['superQueryMatchType'] = this.superQueryMatchType
          }
          var param = Object.assign(sqp, this.queryParam, this.isorter ,this.filters);
          param.field = this.getQueryField();
          param.pageNo = this.ipagination.current;
          param.pageSize = this.ipagination.pageSize;
          return filterObj(param);
        },
        getQueryField() {
          //TODO 字段权限控制
          var str = "id,";
          if(this.columns!=undefined){
            this.columns.forEach(function (value) {
              str += "," + value.dataIndex;
            });
          }
          return str;
        },
        initDictConfig(){
          console.log("--这是一个假的方法!")
        },
        searchQuery() {
          this.loadData(1);
        },
        searchReset() {
          // this.queryParam = {}
          this.loadData(1);
        },
    }

}