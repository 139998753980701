<template>
  <div>
    <!-- 搜索区域 -->
    <div class="info_search" style="left: 30px;position:relative;">
      <a-row :gutter="24">
        <a-col :md="4" :sm="4">
          <j-date v-model="queryParam['date']" placeholder="预约时间"></j-date>
        </a-col>
        <a-col :md="4" :sm="4">
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
            <a-button class="search_btn" @click="lastday">上一天</a-button>
            <a-button class="search_btn" style="margin-left: 8px" @click="nextday">下一天</a-button>
          </span>
        </a-col>
        <a-col :md="4" :sm="4">
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
            <a-button class="search_btn search_query" type="primary" @click="searchQueryNew">查询</a-button>
            <a-button class="search_btn" style="margin-left: 8px" @click="searchReset">重置</a-button>
          </span>
        </a-col>
        <a-col :md="6" :sm="6">
          <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
            <a-button class="apply_btn" type="primary" @click="applyJump">申请会议室</a-button>
            <!-- <a-button class="manage_btn" @click="manageJump" style="margin-left: 8px">会议室管理</a-button> -->
          </span>
        </a-col>
      </a-row>
    </div>

    <!-- table区域 -->
    <a-spin tip="Loading..." size="large" :spinning="spinning">
    <FullCalendar :options="calendarOptions" ref="fullCalendar" />
    </a-spin>
  </div>
</template>
<script>
import { JeecgListMixin } from '@/views/mixins/mixin'
import Vue from 'vue'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
import { getAction } from '@/api/manage'
import moment from 'moment'
// import axios from 'axios'

import { Modal } from 'ant-design-vue'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css' // optional for styling
import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'

import { MESNAC_MEETING_RESERVE_URL } from '@/views/center/modules/config'

export default {
  components: {
    FullCalendar
  },
  mixins: [JeecgListMixin],
  data() {
    return {
      adminURL: window._CONFIG['adminURL'],
      workNo: Vue.ls.get(USER_INFO).workNo,
      token: Vue.ls.get(ACCESS_TOKEN),
      disableMixinCreated: true,
      queryParam: { date: moment().format('YYYY-MM-DD') },
      info: [],
      meetingName: [],
      meetings: [],
      url: {
        list: '/api/public/api/meeting/allMeetingRoomApplyNew'
      },
      newUrl: MESNAC_MEETING_RESERVE_URL,
      meetData: [],
      spinning: false,
      // 日历插件的配置
      calendarOptions: {
        //contentHeight: 'auto',
        contentHeight: 460,
        aspectRation: 1,// 设置日历单元格宽度与高度的比例。
        eventDisplay:'background',
        headerToolbar: false, // 不显示头部按钮
        // nowIndicator: true, // 显示当前时间的标记
        // headerToolbar: {
        //   // 日历头部按钮位置
        //   // left: 'prev',
        //   // center: 'title',
        //   // right: 'next',
        // },
        //locale: 'zh-cn', // 语言
        plugins: [interactionPlugin, resourceTimelinePlugin],
        initialView: 'resourceTimeline',
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        // Fullcalendar的日程调度timeline插件属于增值功能，意思是属于高级功能要貌似要收费，
        // 但是用户可以将该插件用在非营利性项目中。使用timeline插件默认会在页面左下角有版权信息，
        // 但是我们可以将一个参数schedulerLicenseKey的值设置为'GPL-My-Project-Is-Open-Source'就可隐藏左下角的版权内容。
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false, // 是否显示分钟
          meridiem: 'short',
          hour12: false,
        },
        slotDuration: '00:30:00', // 显示时间间隔，默认'00:30:00'，即30分钟。
        resourceAreaHeaderContent: '会议室名称', // 纵轴的第一行 用来表示纵轴的名称
        resourceAreaWidth: '15%',
        //scrollTime: '06:00:00',
        slotMinTime: '07:00:00',
        slotMaxTime: '22:00:00',
        resourceOrder: 'viewOrder',
        resources:[],
        // resources: [{
        //   id: '1',
        //   title: '研发第三会议室',
        //   viewOrder: 1
        // },
        // ], // 日历左侧第一列
        eventColor: '#BE002E',
        loading: (flag) =>{
          this.spinning = flag
        },// loading
        events: [],
        // events: [
        //   { id: '1', resourceId: '1', start: '2022-11-06 08:00:00', end: '2022-11-06 11:00:00', title: '测试会议111' , extendedProps: { sqr: '张三', mobile: '13111111111', lcbh: 'HYYD001001001' } },
        // ], // 日历右侧主体事件数据
        selectable: true,
        select: (selectInfo) => {
          console.log('触发了select-------', selectInfo)
          let that = this
          let roomName = selectInfo.resource._resource.title
          let roomId = selectInfo.resource._resource.id
          let startTime = moment(selectInfo.start).format('YYYY-MM-DD HH:mm')
          let endTime = moment(selectInfo.end).format('YYYY-MM-DD HH:mm')

          let existEvent = this.calendarOptions.events.filter(tmpEvent =>
            tmpEvent.resourceId === roomId && (
              (moment(startTime).isAfter(tmpEvent.start) && (moment(startTime).isBefore(tmpEvent.end))) ||
              (moment(endTime).isAfter(tmpEvent.start) && (moment(endTime).isBefore(tmpEvent.end))) ||
              (moment(endTime).isAfter(tmpEvent.end) && (moment(startTime).isBefore(tmpEvent.start)))
            )
          )
          if (existEvent !== undefined && existEvent !== null && existEvent.length > 0) {
            Modal.error({
              title: '错误',
              content: '您所选时段[' + roomName + ']已被预定，请重新选择！'
            })
            return
          }
          const h = this.$createElement
          this.$confirm({
            title: '确认发起会议室预定流程',
            content: h('div', {}, [
              h('p', '发起[' + startTime + '至' + endTime + ']'),
              h('p', '[' + roomName + ']会议室预定流程？')
            ]),
            onOk() {
              getAction('/mesnac/APPLC/getLCToken', { employeeNo: that.workNo }).then(res => {
                if (res.success) {
                  let access_token = res.result.access_token
                  let tempwindow = window.open('_blank')
                  tempwindow.location = that.adminURL + ':8080/form/detail?startWorkflowCode=HYSYDLC&return=%2Fworkflow-center%2Fstart-workflow&access_token=' + access_token + '&roomId=' + roomId + '&roomName=' + roomName + '&startTime=' + startTime + '&endTime=' + endTime
                } else {
                  that.$message.warning(res.message)
                }
              })
            },
            onCancel() {
            }
          })
        },
        dayClick: (info) => {
          console.log('dayClick-------', info)
        },
        dateClick: (info) => {
          console.log('触发了dateClick-------', info)
        },
        // 鼠标移入效果
        eventMouseEnter: (info) => {
          console.log('鼠标移入效果--------', info)
          let eve = info.event

          tippy(info.el, {
            content:
              '<div style=\'width: 335px; font-size:12px; overflow:hidden; padding:10px 0 10px; z-index:1;\'>' +
              '<div style=\'color:#000;font-size:14px;font-weight:700;\'>' +
              eve.extendedProps.hyzt +
              '</div>' +
              '<div style=\'border-bottom: 0.01rem solid #D8D8D8;line-height: 30px;color: #333;font-size:12px;\'>' +
              eve.getResources()[0].title +
              '</div>' +
              '<div style=\'color: #333;font-size:12px;line-height: 30px;border-bottom: 0.01rem solid #D8D8D8;\'>使用时间：' +
              moment(eve.start).format('YYYY-MM-DD HH:mm:ss') + ' 至 ' + moment(eve.end).format('YYYY-MM-DD HH:mm:ss') +
              '</div>' +
              '<div style=\'color: #333;font-size:12px;margin-top:10px;\'>申请人：' +
              eve.extendedProps.sqr +
              '</div>' +
              '<div style=\'color: #333;font-size:12px;margin-top:5px;\'>联系电话：' +
              eve.extendedProps.mobile +
              '</div>' +
              '</div>',
            theme: 'light-border',
            // trigger: 'click',
            interactive: true,
            animation :'',
            // placement: 'right-end',
            appendTo: document.body, // 解决 interactive 为true的时候 z-index问题
            placement: 'auto',
            allowHTML: true,
            zIndex: 99999
          })
        },
        // 点击
        eventClick: (info) => {
          console.log('点击弹出详情弹窗--------', info)
        }
      }
    }
  },
  created() {
    //this.loadData()
    this.getMeetingRoomInfo()
  },
  mounted() {
    // 根据预约信息数组，处理成表格可以使用的形式
    this.getMeetingRoomReserveInfo()
  },
  methods: {
    // 获取会议室信息
    getMeetingRoomInfo() {
      getAction(this.newUrl.getMeetingRoomInfo, {}).then(res => {
        if (res.success) {
          this.calendarOptions.resources = res.result
          this.getMeetingRoomReserveInfo()
        }
      })
    },
    // 获取会议室预约信息
    getMeetingRoomReserveInfo(){
      console.log('get date:', this.queryParam['date'])
      this.calendarOptions.loading(true)
      getAction(this.newUrl.getMeetingRoomReserveInfo, {'date': this.queryParam['date']}).then(res => {
        if (res.success) {
          this.calendarOptions.events = res.result
          this.calendarOptions.loading(false)

          let calendarApi = this.$refs.fullCalendar.getApi()
          calendarApi.gotoDate(this.queryParam['date'])
        }
      })
    },
    // 加载预约会议室
    loadData() {
      var params = this.getQueryParams()
      getAction('/api/public/api/meeting/allMeetingRoomApplyNew',params ).then(res => {
        // console.log('res: ', res)
        // if(res.msg){
        //   this.dataSource = res.data
        //   console.log('this.dataSource: ', this.dataSource)
        //   let meetings = []
        //   this.meetingName = []
        //   this.dataSource.forEach(item => {
        //     this.meetingName.push(item.hysmc)
        //     if (item.startTime) {
        //       let a = item.startTime.map((key,index) => {
        //         let data = {}
        //         data.start = starts[key]
        //         data.time = starts[item.endTime[index]] - starts[key]
        //         data.pass = index%2 == 0
        //         return data
        //       })
        //       meetings.push(a)
        //     }else {
        //       meetings.push([])
        //     }
        //
        //   })
        //   this.meetings = meetings
        // }
      })
        .finally(() => {
          this.loadTimeForm()
        })
      // axios({
      //   method:'get',
      //   url:`${this.adminURL}:8080/api/public/api/meeting/allMeetingRoomApplyNew`,
      //   params:params,
      // }).then(res => {
      //   if(res.data.msg){
      //     this.dataSource = res.data.data
      //     let meetings = []
      //     this.meetingName = []
      //     this.dataSource.forEach(item => {
      //       this.meetingName.push(item.hysmc)
      //       if (item.startTime) {
      //         let a = item.startTime.map((key,index) => {
      //           let data = {}
      //           data.start = starts[key]
      //           data.time = starts[item.endTime[index]] - starts[key]
      //           data.pass = index%2 == 0
      //           return data
      //         })
      //         meetings.push(a)
      //       }else {
      //         meetings.push([])
      //       }
      //     })
      //     this.meetings = meetings
      //   }
      // }).finally(() => {
      //   this.loadTimeForm()
      // })
    },
    nextday() {
      this.queryParam = { date: moment(this.queryParam.date).add(1, 'days').format('YYYY-MM-DD') }
      //this.loadData()
      this.getMeetingRoomReserveInfo()
    },
    lastday() {
      this.queryParam = { date: moment(this.queryParam.date).subtract(1, 'days').format('YYYY-MM-DD') }
      //this.loadData()
      this.getMeetingRoomReserveInfo()
    },
    searchReset() {
      this.queryParam = { date: moment().format('YYYY-MM-DD') }
      //this.loadData()
      this.getMeetingRoomReserveInfo()
    },
    searchQueryNew(){
      this.getMeetingRoomReserveInfo()
    },
    // 会议室管理
    manageJump() {
      var tempwindow = window.open('_blank')
      tempwindow.location = `${this.adminURL}:9001/zmehysgl/i7jguHysgl?token=${this.token}`
    },
    // 申请会议
    applyJump() {
      getAction('/mesnac/APPLC/getLCToken', { employeeNo: this.workNo }).then(res => {
        if (res.success) {
          let access_token = res.result.access_token
          var tempwindow = window.open('_blank')
          tempwindow.location = this.adminURL + ':8080/form/detail?startWorkflowCode=HYSYDLC&return=%2Fworkflow-center%2Fstart-workflow&access_token=' + access_token
        } else {
          this.$message.warning(res.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.cell {
  /*width: 100%;*/
  /*height: 100%;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  width: 100%;
  height: 100%;
  display: inline-block;
  align-items: center;
  vertical-align: middle;
  background: rgba(188, 0, 57, 100);
  box-sizing: border-box;
}

.cell2 {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
  background: rgba(188, 0, 57, 100);
  box-sizing: border-box;
}

.table_warp {
  // border: 1px solid rgba(188, 0, 57, 100);
  width: 95%;
  margin: 0 auto;

  thead {
    border: 1px solid rgba(188, 0, 57, 100);
  }
}

.tbody_th_name {
  height: 41px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  border: 1px solid rgba(188, 0, 57, 100);
  background: rgba(255, 255, 255, 100);
  color: #101010;
}

.thead_tr {
  height: 50px;
  line-height: 50px;
  // border-bottom: 1px solid rgba(188, 0, 57, 100);
}

.thead_th {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  font-size: 14px;
  font-weight: 300;
  color: #101010;
  text-align: center;
  border-bottom: 1px solid rgba(188, 0, 57, 100);
}

.thead_th_one {
  font-weight: bold;
  text-align: center;
  width: 155px;
}

.mytd {
  cursor: pointer;
  width: 42px;
  height: 42px;
  text-align: center;
  margin: 0 auto;
  border: 1px solid rgba(188, 0, 57, 100);
}

.mytd:hover {
  background: rgba(255, 247, 247, 100);
  // border: 2px solid blue;
}


.info_search {
  margin-bottom: 20px;

  /deep/ .ant-row {
    .ant-calendar-picker {
      .ant-calendar-picker-input {
        height: 40px;
        border: 1px solid rgba(188, 0, 57, 100);
        border-radius: 10px;
        color: rgba(188, 0, 57, 100);
        font-size: 18px;

        &::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          font-size: 18px;
          color: rgba(188, 0, 57, 100);
        }
      }

      .anticon {
        color: #bc0039;
      }
    }
  }

  .search_input {
    height: 40px;
    border: 1px solid rgba(188, 0, 57, 100);
    border-radius: 10px;
    color: rgba(188, 0, 57, 100);
    font-size: 18px;

    &::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      font-size: 18px;
      color: rgba(188, 0, 57, 100);
    }
  }

  .search_btn {
    // width: 67px;
    height: 40px;
    text-align: center;
    font-size: 16px;
    color: #bc0039;
    border: 1px solid #bc0039;
    border-radius: 10px;
    padding: 0px 10px;
  }

  .search_query {
    background-color: #ffeded;
  }

  .apply_btn {
    width: 120px;
    height: 40px;
    font-size: 16px;
    color: #fff;
    background: #BC0039;
    border: 1px solid #bc0039;
    border-radius: 10px;
    padding: 0px;
  }

  .manage_btn {
    width: 120px;
    height: 40px;
    font-size: 16px;
    color: #000;
    background: #ECF1F9;
    border: 1px solid #A7CAF7;
    border-radius: 10px;
    padding: 0px;
  }
}

.info_table {
  /deep/ .ant-table-placeholder {
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }

  /deep/ .ant-table-tbody {
    border-bottom: 1px solid #bc0039;
    border-top: 1px solid #bc0039;
  }

  /deep/ .ant-pagination {
    .ant-pagination-next, .ant-pagination-prev {
      .ant-pagination-item-link {
        &:hover {
          color: #BC0039;
        }
      }
    }

    .ant-pagination-next:focus .ant-pagination-item-link {
      color: #BC0039;
    }

    .ant-pagination-item {
      a:hover {
        color: #BC0039;
      }
    }

    .ant-pagination-item-active {
      border-color: #BC0039;

      a {
        color: #BC0039;
      }
    }
  }
}

/deep/ a{ color: #2d3436;}

/deep/ .fc-theme-standard .fc-scrollgrid{
  border-width: 1px;
  border-color: var(--fc-border-color, #ac233c)
}

/deep/ .fc-theme-standard td{
  border-width: 1px;
  border-color: var(--fc-border-color, #ac233c)
}

/deep/ .fc-theme-standard th{
  border-width: 1px;
  border-color: var(--fc-border-color, #ac233c)
}

/deep/ .fc-datagrid-cell-main{
  font-weight: bolder;
  color: #2d3436;
}

/deep/ .fc-bg-event{
  opacity: 0.8;
  color: #FFFF;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  text-overflow: ellipsis;
}

/deep/ .fc-event-title{
  font-style: normal;
}

</style>


